// extracted by mini-css-extract-plugin
export var bg = "fullWidthContentPage-module--bg--Wx9Pz";
export var bgdesktop = "fullWidthContentPage-module--bgdesktop--QxyTV";
export var bgmobile = "fullWidthContentPage-module--bgmobile--i0IRG";
export var bgtablet = "fullWidthContentPage-module--bgtablet--EtNcz";
export var bgxl = "fullWidthContentPage-module--bgxl--rQ7E-";
export var blurb = "fullWidthContentPage-module--blurb--eQKWv";
export var bottom = "fullWidthContentPage-module--bottom--oG9Nh";
export var contentleft = "fullWidthContentPage-module--contentleft--9qi5W";
export var cta = "fullWidthContentPage-module--cta--o0SL2";
export var ctacopy = "fullWidthContentPage-module--ctacopy--49BCH";
export var heading = "fullWidthContentPage-module--heading--lRmt+";
export var icon = "fullWidthContentPage-module--icon--BTuD6";
export var icongray = "fullWidthContentPage-module--icongray--F6wBH";
export var iconyellow = "fullWidthContentPage-module--iconyellow--OLapY";
export var link = "fullWidthContentPage-module--link--C7lz5";
export var linkcard = "fullWidthContentPage-module--linkcard--XxEni";
export var linkcards = "fullWidthContentPage-module--linkcards--48tTK";
export var linkhover = "fullWidthContentPage-module--linkhover--t8Cfv";
export var logo = "fullWidthContentPage-module--logo--wCBzY";
export var logolink = "fullWidthContentPage-module--logolink--ZNBdX";
export var patientcontent = "fullWidthContentPage-module--patientcontent--WYX9i";
export var patientname = "fullWidthContentPage-module--patientname--ivZWS";
export var right = "fullWidthContentPage-module--right--kvCSy";
export var rightInner = "fullWidthContentPage-module--rightInner--fhysL";
export var screenreader = "fullWidthContentPage-module--screenreader--Es5hr";
export var title = "fullWidthContentPage-module--title--I9nMg";
export var top = "fullWidthContentPage-module--top--B4SRt";